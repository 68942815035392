<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FF507D;"
      d="M412.388,121.303c-0.103-4.718-3.957-8.489-8.676-8.489H108.288c-4.719,0-8.574,3.772-8.676,8.489
	l-8.3,381.83C91.205,507.998,95.12,512,99.988,512h312.025c4.866,0,8.782-4.002,8.676-8.867L412.388,121.303z"
    />
    <g>
      <path
        style="fill:#D23C69;"
        d="M240.551,477.288c-53.532,0-96.599-44.014-95.435-97.533l5.803-266.942h-42.632
		c-4.72,0-8.574,3.772-8.676,8.489l-8.3,381.83C91.205,507.998,95.12,512,99.988,512h312.025c4.866,0,8.782-4.002,8.676-8.867
		l-0.562-25.845H240.551z"
      />
      <circle style="fill:#D23C69;" cx="195.254" cy="164.881" r="21.695" />
      <circle style="fill:#D23C69;" cx="316.746" cy="164.881" r="21.695" />
    </g>
    <g>
      <path
        style="fill:#FFDC64;"
        d="M177.898,418.712c-2.78,0-5.551-1.06-7.669-3.178c-4.237-4.237-4.237-11.101,0-15.339
		l156.203-156.203c4.237-4.237,11.101-4.237,15.339,0c4.238,4.237,4.237,11.101,0,15.339L185.569,415.534
		C183.449,417.652,180.677,418.712,177.898,418.712z"
      />
      <path
        style="fill:#FFDC64;"
        d="M203.932,323.254c-25.118,0-45.559-20.441-45.559-45.559s20.441-45.559,45.559-45.559
		s45.559,20.441,45.559,45.559S229.051,323.254,203.932,323.254z M203.932,253.831c-13.161,0-23.864,10.703-23.864,23.864
		s10.703,23.864,23.864,23.864c13.161,0,23.864-10.703,23.864-23.864S217.093,253.831,203.932,253.831z"
      />
      <path
        style="fill:#FFDC64;"
        d="M308.068,427.39c-25.118,0-45.559-20.441-45.559-45.559s20.441-45.559,45.559-45.559
		s45.559,20.441,45.559,45.559S333.186,427.39,308.068,427.39z M308.068,357.966c-13.161,0-23.864,10.703-23.864,23.864
		s10.703,23.864,23.864,23.864c13.161,0,23.864-10.703,23.864-23.864S321.229,357.966,308.068,357.966z"
      />
      <path
        style="fill:#FFDC64;"
        d="M316.746,173.559c-4.797,0-8.678-3.881-8.678-8.678V69.424c0-28.712-23.356-52.068-52.068-52.068
		s-52.068,23.356-52.068,52.068v95.458c0,4.797-3.881,8.678-8.678,8.678s-8.678-3.881-8.678-8.678V69.424
		C186.576,31.144,217.72,0,256,0s69.424,31.144,69.424,69.424v95.458C325.424,169.678,321.543,173.559,316.746,173.559z"
      />
    </g>
    <g>
      <path
        style="fill:#FFC850;"
        d="M195.254,173.559L195.254,173.559c-4.792,0-8.678-3.886-8.678-8.678v-17.356
		c0-4.792,3.886-8.678,8.678-8.678l0,0c4.792,0,8.678,3.886,8.678,8.678v17.356C203.932,169.674,200.047,173.559,195.254,173.559z"
      />
      <path
        style="fill:#FFC850;"
        d="M316.746,173.559L316.746,173.559c-4.792,0-8.678-3.886-8.678-8.678v-17.356
		c0-4.792,3.886-8.678,8.678-8.678l0,0c4.792,0,8.678,3.886,8.678,8.678v17.356C325.424,169.674,321.538,173.559,316.746,173.559z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
